import React from 'react';

import { layout, faqSection } from './faq.module.scss';
import { faqToJsonLd } from '../utills/faq-to-json-ld';
import { useFaq } from '../hooks/use-faq';

import SEO from '../components/seo';
import Main from '../layouts/main';
import Faq from '../components/organisms/faq';

const FaqPage = () => {
    const faqs = useFaq();

    return (
        <>
            <SEO
                title="FAQ"
                structuredData={{
                    '@type': 'FAQPage',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    mainEntity: faqs.map((faq) => faqToJsonLd(faq)),
                }}
            />
            <Main className={layout}>
                <Faq className={faqSection} TitleTag="h1" />
            </Main>
        </>
    );
};

export default FaqPage;
