import React from 'react';

export function getLessImportantHeading(
    headingTag: string | React.ElementType | undefined
): React.ElementType | undefined {
    if (!headingTag) return;
    if (typeof headingTag !== 'string') return;
    return headingMap[headingTag];
}

const headingMap: Record<string, React.ElementType> = {
    h1: 'h2',
    h2: 'h3',
    h3: 'h4',
    h4: 'h5',
    h5: 'h6',
    h6: 'p',
};
