export function faqToJsonLd({ title, content }) {
    return {
        '@type': 'Question',
        name: title,
        acceptedAnswer: {
            '@type': 'Answer',
            text: content,
        },
    };
}
